// 
// page: menu
// 

.section-menu {
  position: relative;
  padding: 50px 0;
  overflow: hidden;
  background-color: #f9f9f9;
  .menu-image-1, .menu-image-2 {
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-blend-mode: darken;
    background-color: #f9f9f9;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .menu-image-1 {
    background-position: left top;
    left:0;
    width: 50%;
  }
  .menu-image-2 {
    background-position: right top;
    right: 0;
    width: 50%;
  }
  .menu-nav-tabs {
    margin-top: 20px;
    ul.nav-tabs {
      border: none;
      margin-left: -0.75rem;
      border-bottom: 1px solid $gray-300;
      li.nav-item {
        margin-right: 20px;
        margin-bottom: 0;
        a.nav-link {
          border: none;
          color: rgba($text-color, .5);
          text-transform: uppercase;
          font-weight: 400;
          font-size: 18px;
          padding: 1rem;
          &:hover, &.active {
            background: none;
            color: $text-color;
          }
        }
      }
    }
  }
  .menu-download {
    margin-top: 20px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-weight: 300;
      font-size: 14px;

    }
    a.download-link {
      margin-left: 5px;
      text-transform: uppercase;
      display: flex;
      color: $text-color;
      font-weight: bold;
      font-size: 14px;
      i {
        align-self: center;
        margin-left: 5px;
        font-size: 18px;
      }
    }
  }
  .menu-tab-content {
    li.item {
      border-bottom: 1px solid $gray-300;
      padding: 20px;
      margin-left: -20px;
      margin-right: -20px;
      position: relative;
      & > a {
        font-family: 'Great Vibes', sans-serif;
        font-weight: 400;
        color: $secoundary;
        font-size: 40px;
        display: block;
        &[data-toggle="collapse"] {
          &.collapsed {
            color: $text-color;

            &:hover {
              color: $secoundary;
              &:before {
                color: $secoundary;
              }
            }

            &:before {
              color: $text-color;
              content: "\e914";
            }
          }

          &:before {
            font-family: 'icomoon' !important;
            font-size: 10px;
            position: absolute;
            right: 25px;
            top: 25px;
            color: $secoundary;
            content: "\e910";
          }
          &:hover {
            color: $text-color;
            &:before {
              color: $text-color;
            }
          }
        }
      }
    }
  }
  ul.menu-list {
    li {
      &:not(:last-of-type) .menu-list-item {
        border-bottom: 1px solid $gray-300;
      }
      &:last-of-type .menu-list-item {
        padding-bottom: 0;
      }
      .menu-list-item {
        display: flex;
        align-items: center;
        padding: 20px;
        margin-left: -20px;
        margin-right: -20px;

        .list-item-description {
          flex: 1;

          h6, .h6 {
            font-size: 16px;
            font-weight: 600;
            color: $text-color;
            margin-bottom: 3px;
          }

          p {
            font-size: 15px;
            font-weight: 300;
            color: rgba(#060606, .7);
            margin-bottom: 0;
          }
        }

        .list-item-price {
          width: 20%;
          text-align: right;

          .price {
            font-weight: 400;
            font-size: 32px;

            span {
              margin-left: 4px;
              font-weight: 300;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@include media-breakpoint-down(md) {
  .section-menu {
    padding: 25px 0;
  }
}

@include media-breakpoint-down(sm) {
  .section-menu {
    .menu-image-1, .menu-image-2 {
      display: none;
    }
  }

}


