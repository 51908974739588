// 
// Base: List
// 

ul, ol {
    @extend .list-unstyled;
}

// list-bulets
ul.list-bulets{
    position: relative;
    font-size: 14px;
    font-weight: 400;
    margin: 30px 0;
    &:before{
        content: "";
        position: absolute;
        left: -60px;
        top: 10px;
        height: 84px;
        width: 1px;
        background-color: $secoundary;
    }
    li{
        position: relative;
        padding-left: 12px;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 9px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $text-color;
        }
    }
}

// 
// download-list
// 

ul.download-list{
    margin: 80px 0px 65px 0px;
    transform: translateX(-35px);
    li{
        margin-bottom: 10px;
    }
}
.download-item{
    font-size: 14px;
    padding: 20px 35px;
    padding-right: 140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    border: 1px solid $secoundary;
    transition: all 0.3s 0s;
    padding-right: 230px;
    
    .download-text{
        font-weight: 400;
        font-size: 14px;
        color: $white;
        transition: all 0.3s 0s;

    }
    
    .download-link{
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        right: 0;
        width: 210px;
        background-color: #6c4536;
        color: $white;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        transition: all 0.3s 0s;
        padding-right: 40px;
    }
}

.download-item:hover{
    .download-link{
        background-color: $secoundary;
        color: $white;
    }
}

@include media-breakpoint-down(xl) {
    .download-item{
        padding-right: 200px;
        .download-link{
            width: 180px;
            flex: 0 0 180px;
        }
    }
}


@include media-breakpoint-down(md) {
    ul.download-list{
        margin: 60px 0px 40px 0px;
        transform: translateX(0px);
    }
}


@include media-breakpoint-down(sm) {
    .download-item{
        padding-right: 140px;
        .download-link{
            width: 120px;
            flex: 0 0 120px;
            padding-right: 20px;
        }
    }
}

