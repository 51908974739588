// 
// page: gallery
// 

.section-gallery {
  .nav-filter {
    display: flex;
    margin-left: -0.75rem;
    li.filter-mansory {
      a {
        margin-right: 20px;
        color: rgba($text-color, .5);
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        padding: 1rem;
      }
      &.active a, a:hover {
        color: $text-color;
      }
    }
  }
  ul.grid-mansory {
    margin-bottom: 0;
  }
  .mansory-item {
    display: block;
    position: relative;
    padding-bottom: 62.5%;
    outline: none !important;
    margin: 0 -2px;
    .mansory-image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-position: 50%;
    }
    .mansory-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($black, .4);
      transition: all .4s;
    }
    &:hover .mansory-mask {
      background-color: transparent;
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(sm) {

}


