//
// component: hero
//

.hero-background{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero-small {
    min-height: 750px;
}

.hero-title {
    padding-top: 40vh;
    h1, .h1 {
        text-align: center;
        font-size: 90px;
        color: $white;
        font-weight: 600;
        margin-bottom:0;
    }
}


@include media-breakpoint-down(xl) {
    .hero-background{
        min-height: 90vh;
    }
    .hero-title {
        padding-top: 37vh;
    }
    .hero-background.hero-small {
        min-height: 600px;
    }

}
@include media-breakpoint-down(lg) {
    .hero-background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50%;
    }
    .hero-background{
        min-height: 600px;
    }
    .hero-title {
        padding-top: 230px;
    }
    .hero-background.hero-small {
        min-height: 500px;
    }
}

@include media-breakpoint-down(md) {
    .hero-background{
        min-height: 500px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 60px;
        }
        h2, .h2 {
            font-size: 25px;
        }
    }
    .hero-background.hero-small {
        min-height: 400px;
    }
}

@include media-breakpoint-down(sm) {
    .hero-background.hero-small {
        //min-height: 5vh;
    }
    .hero-background {
        min-height: 400px;
    }
    .hero-title {
        padding-top: 165px;
    }
    .hero-title {
        h1, .h1 {
            font-size: 40px;
            line-height: 45px;
        }
    }
    .hero-background.hero-small {
        min-height: 250px;
    }
}