// 
// Modal
// 

.modal-content{
    background-color: $white;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-content, .modal-footer, .modal-content{
    border-radius: 0px;
}

.modal-header, .modal-footer{
    border:0px;
}

button.close{
    color: $black;
}

#modalSp{
    .modal-body{
        padding: 40px 20px;
    }

    ul > li > a{
        display: none;
    } 

    .hours, .phone-group{
        p{
            margin: 0;
        }
    }

    .box-head{
        p{
            margin: 0;
        }
    }
    .shop-content{
        margin-bottom: 30px;
    }

    .shop-image{
        margin: 20px 0;
    }
}


#modalShops{
    background-color: rgba($dark, 0.85);
    .modal-dialog{
        width: 1420px;
        max-width: 95%;
    }

    .close{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 33;
        background-color: $black;
        color: $white;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        font-size: 10px;
    }

    .close:hover{
        background-color: $secoundary;
    }

    .modal-body{
        padding: 0;
    }

    #modalShops-content{
        text-align: center;
    }

    .modal-container{
        display: flex;
        .modal-container-body{
            flex: 0 0 40%;
            padding: 40px;
            background-color: #fff;
            color: $dark;
            .h1, .h2, .h3, .h4, .h5, .h6{
                color: $dark;
            }

            .shop-item-inner{
                display: inline-block;
                max-width: 260px;
                text-align: left;
            }

            .shop-item-title{
                p{
                    margin: 0;
                }
            }
        }
        .modal-container-image{
            flex: 0 0 60%;
            background-size: cover;
            background-position: 50%;
        }
    }
}


@include media-breakpoint-down(sm) {
    #modalShops{
        .modal-container{
            display: flex;
            flex-wrap: wrap;
            .modal-container-body{
                flex: 0 0 100%;
                padding: 20px;
                order: 2;
            }
            .modal-container-image{
                flex: 0 0 100%;
                order: 1;
                height: 220px;
            }
        }

        .shop-item-logo{
            margin: 10px 0;
        }
    }
}