//
// mfp
// 


//arrows
.mfp-arrow-left,
.mfp-arrow-right {
    position: absolute;
    z-index: 8888;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $dark;
    background-color: $white;
    width: 50px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: center;
    &:after {
        color: $black;
        font-size: 10px;
        font-family: 'icomoon' !important;
        transition: all 0.25s;
    }
    &:hover{
        &:after{
            color:$secoundary;
        }
    }
}

.mfp-arrow-left {
    left: 25px;
    &:after {
        content: "\e901";
    }
}

.mfp-arrow-right {
    right: 25px;
    &:after {
        content: "\e902";
    }
}

// close
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 99;
    color: $dark;
    background-color: $white;
    opacity: 1;
    width: auto;
    font-size: 24px;
    padding: 12px;
    cursor: pointer !important;
    width: auto;
    height: auto;
    line-height: 1;
    transition: all 0.3s;
}

.mfp-close:active {
    top: 0px;
}

.mfp-close:hover {
    color: $secoundary;
}

// content
.mfp-container {
    padding: 60px 120px;
}

.mfp-bottom-bar, .mfp-counter {
    display: none;
}

img.mfp-img {
    padding: 0;
}

.mfp-figure figure {
    padding: 8px;
    background-color: $white;
}

// iframe
.mfp-iframe-holder .mfp-content {
    padding: 8px;
    background-color: $white;
}

.mfp-iframe-scaler iframe {
    box-shadow: none !important;
}

/* =transition */

.mfp-ready .mfp-figure {
    opacity: 0;
}

.mfp-zoom-in {
    /* start state */
    /* animate in */
    /* animate out */
}

.mfp-zoom-in .mfp-figure,
.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-image-loaded .mfp-figure,
.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-in.mfp-ready .mfp-preloader {
    opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-figure,
.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
    opacity: 0;
}

.mfp-iframe-scaler {
    overflow: visible;
    /*so the close button is shown*/
}

.mfp-zoom-out-cur {
    cursor: auto;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}

@include media-breakpoint-down(xl) {
    .mfp-bottom-bar {
        padding: 30px;
    }
}

@include media-breakpoint-down(md) {
    .mfp-bottom-bar {
        position: static;
        background-color: $white;
        padding-top: 20px;
        margin-top: 0;
        h3,
        h6,
        p {
            color: $primary;
        }
        p {
            font-size: 14px;
        }
    }
}

@include media-breakpoint-down(md) {
    .mfp-arrow-left,
    .mfp-arrow-right {
        font-size: 12px;
        width: 50px;
        height: 50px;
    }
}

@include media-breakpoint-down(sm) {
    .mfp-container {
        padding: 40px 10px;
    }
    .mfp-figure figure {
        padding: 38px 0px;
        background-color: transparent;
    }
    .mfp-image-holder .mfp-close {
        top: 8px;
        right: 5px;
        width: 30px;
        height: 30px;
    }
    .mfp-arrow-left, .mfp-arrow-right{
        width: 30px;
        height: 30px;
            font-size: 8px;
    }
    .mfp-arrow-left {
        left: 10px;
    }
    .mfp-arrow-right {
        right: 10px;
    }
    .mfp-image-holder .mfp-close,
    .mfp-iframe-holder .mfp-close {
        font-size: 14px;
        padding: 8px;
    }
}

