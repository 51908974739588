// 
// page: home
//
.page-home {
  .section-menu {
    .menu-title {
      text-align: center;
    }
    .menu-nav-tabs {
      margin-top: 40px;
      ul.nav-tabs {
        align-items: center;
        justify-content: center;
        margin-left: 0;
        border-bottom: 0;
        li.nav-item {
          margin-left: 10px;
          margin-right: 10px;
          a.nav-link {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}

.main-scroll {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  .link-top {
    position: relative;
    padding-bottom: 90px;
    text-transform: uppercase;
    line-height: 1;
    display: block;
    font-weight: 600;
    transition: all 0.3s;
    animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
  }

  .link-top:before {
    content: "\e900";
    font-family: 'icomoon' !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    bottom: 15px;
    font-size: 45px;
    transition: all 0.3s;
  }
}
@keyframes anim-bounce-sm {
  from {
    top: 0px; }
  to {
    top: 7px; }
}

.section-about {
  background-position: right top;
  background-repeat: no-repeat;
  padding: 70px 0;
  h2, .h2 {
    margin-bottom: 100px;
  }
  .about-number {
    margin-top: 25px;
    h5 {
      font-weight: 900;
      font-size: 160px;
      line-height: 125px;
      color: #c9c9c9;
    }
    p {
      margin-left: 35px;
      color: #c9c9c9;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.2;
    }
  }
  .about-text {
    margin-top: 30px;
    h4, .h4 {
      font-weight: bold;
      margin-bottom: 30px;
    }
    p {
      font-weight: 200;
    }
    .btn {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .about-image {
    text-align:center;

  }
}

.section-images {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .image-holder {
    height: 530px;
  }
}

.section-offers {
  margin-top: 10px;
  .row.no-gutters {
    margin-left: -10px;
    margin-right: -10px;
  }
  .image-holder {
    padding-bottom: 72.5%;
    transition: all .4s cubic-bezier(.365,.84,.44,1);
    .image-holder-bg {
      background-blend-mode: normal;
      transition: all .4s cubic-bezier(.365,.84,.44,1);
      &:after {
        content: '';
        top:0;bottom:0;left:0;right:0;
        position:absolute;
        background-color: rgba($black, .3);
      }
    }
    &:hover {
      .image-holder-bg {
        background-color: #30a45e;
        background-blend-mode: multiply;
      }
      .offer .btn {
        background-color: #30a45e;
        color: $white;

      }
    }
  }
  .offer {
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h3,p {
      color: $white;
      margin-bottom: 30px;
    }
    p { max-width: 320px; }
    .btn {
      background-color: none;
      padding: 6px 12px;
      border: none;
      &:hover {
        background-color: #30a45e;
        color: $white;
      }
      &:before{
        content: none;
      }
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
  .section-images {
    .image-holder {
      height: 330px;
    }
  }
}

@include media-breakpoint-down(md) {
  .section-offers {
    .image-holder {
      padding: 40px 20px;
    }
    .offer {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
    }
  }
  .section-about {
    padding: 30px 0;
    background: none !important;
    h2, .h2 {
      margin-bottom: 0;
    }
  }
  .section-images {
    .image-holder {
      height: 330px;
    }
  }
  .page-home {
    .section-menu {
      .menu-nav-tabs {
        margin-top: 20px;
        ul.nav-tabs {
          align-content: space-between;
          li.nav-item {
            margin: 0;
            a.nav-link {
              padding: 0.5rem 0.5rem;
            }
          }
        }
      }
    }
  }

}

@include media-breakpoint-down(sm) {

}


