.swiper-map-container{
    position: relative;
    .section-box{
        position: absolute;
        z-index: 99;
        right: 0px;
        z-index: 10;
        top: 5%;
        bottom: 5%;
        width: 80%;
        opacity: 0;
        transform: translateX(100%);
        transition: all 0.3s;

        .box-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px;
            flex: 0 0 40%;
        }

        .box-image{
            flex: 0 0 60%;
            background-size: cover;
            background-position: 50%;
        }

        .slide-map-image{
            display: none;
        }
        
    }

    .section-box.active{
        transform: translateX(0%);
        opacity: 1;
    }
    .swiper-wrapper{
        align-items: flex-end;
    }
    .swiper-slide{
        width: auto;
        padding-top: 60px;
    }

    .swiper-plan-nav{
        margin-top: 40px;
        padding-left: 65px;
        display: flex;
        align-items: center;
        a{
            color: $white;
            margin: 0 15px;
            &:hover{
                color: $secoundary;
            }
        }
        .shop-list{
            display: flex;
            align-items: center;
            font-size: 12px;

            i{
                font-size: 16px;
                margin-left: 12px;
            }
        }
        .swiper-map-separator{
            width: 20px;
            height: 10px;
        }
    }
}

.slide-map{
    position: relative;
    
    cursor: pointer;
    .image-svg{
        position: absolute;
        transition: all 0.3s;
        opacity: 0;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: flex-end;
        img{
            width: 100%;
            height: auto;
        }
    }

    .image-svg-title{
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translate(-50%, -100%);
        color: $secoundary;
        font-size: 16px;

        opacity: 0;
        transition: all 0.3s;
        &:before{
            content: "";
            position: absolute;
            top: 24px;
            left: 50%;
            width: 1px;
            height: 0px;
            background-color: $secoundary;
            transition: all 0.3s;
        }
    }

    .slide-map-content, .slide-map-shop-list{
        display: none;
    }

    .slide-map-image{
        display: none;
    }
}

.slide-map:hover, .slide-map.active{
    .image-svg{
        opacity: 0.45;
    }
    .image-svg-title{
        opacity: 1;
        transform: translate(-50%, 0%);
        &::before{
            height: 20px;
        }
    }
}

.slide-map.active{
    .image-svg{
        opacity: 0.8;
    }
}

.map-shop-list{
    position: absolute;
    overflow: hidden;
    padding: 60px 20px 60px 60px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.8);
    transition: all 0.3s;
    z-index: -1;
    top: 20%;
    bottom: 20%;
    left: 0%;
    width: 20%;
    background-color: rgba(#101415, 0.85);
    div[data-simplebar]{
        height: 100%;
    }
    li{
        margin-bottom: 10px;
    }
    li:last-child{
        margin-bottom: 0;
    }
    a{
        color: #7f8181;
        &:hover{
            color: $white;
        }
    }
    li.active a{
        color: $white;
    }

    .shop-content{
        display: none;
    }
}

.map-shop-list.active{
    z-index: 20;
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
}


@include media-breakpoint-down(xl) {
    .swiper-map-container{
        .section-box{
            top: 12%;
            bottom: 12%;
            width: 75%;
        }
    }

    .map-shop-list{
        width: 25%;
    }
}


@include media-breakpoint-down(lg) {
    .swiper-map-container{
        .section-box{
            width: 75%!important;
        }
    }

    .map-shop-list{
        padding: 30px 20px;
    }
}

@include media-breakpoint-down(sm) {
    .slide-map{
        padding: 0;
        text-align: center;
    }

    .swiper-map-container {
        .section-box{
            display: none;
        }
        .swiper-slide{
            padding-top: 0;
        }
    }

    .slide-map .image-svg-title{
        display: none;
    }
}