// 
// component: Sidebar
// 

.sidebar{
    max-width: 340px;
    padding: 45px 40px 20px 40px;
    background-color: $white;
    color: $dark;
    .widget{
        margin-bottom: 40px;
    }
    .widget-title{

        font-size: 22px;
        font-weight: 400;
        margin-bottom: 20px;
        color: $dark;
    }

    .widget-menu{
        margin-top: 10px;
        li{
            margin-bottom: 6px;
        }
        li > a {
            font-size: 14px;
            color: $dark;
            &:hover{
                color: $secoundary;
            }
        }
    }
    
    ul.recent-posts-list{
        padding: 0;
        margin: 0;
        > li{
            padding: 20px 0px;
            border-bottom: 1px solid $gray-200;
        }
        > li:first-child{
           padding-top: 0;
        }
        a:hover{
            color: $secoundary;
        }
    }

    .recent-post{
        display: flex;
        .recent-post-image{
            flex: 0 0 85px;
            .image-holder{
                padding-bottom: 90%;
            }
        }
        .recent-post-body{
            padding: 5px 0px 5px 20px;
            .post-meta{
                font-size: 12px;
                margin-bottom: 2px;
                color: $dark;
                font-weight: 700;
            }
            .post-short{
                font-size: 12px;
                color: $dark;
            }
        }
    }
}

.sidebar-top{
    position: relative;
    z-index: 5;
    transform: translateY(-40%);
}


@include media-breakpoint-down(xl) {
    .sidebar-top{
        transform: translateY(-30%);
    }
}


@include media-breakpoint-down(lg) {
    .sidebar-top{
        transform: translateY(-10%);
    }
}



@include media-breakpoint-down(md) {
    .sidebar{
        max-width: 100%;
        margin-top: 30px;
    }
}