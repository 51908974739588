
.section{
    position: relative;
    overflow: hidden;
}

.section-relative{
    position: relative;
}

.page-header {
    .section-title {
        padding-top: 0;
    }
}
.section-title {
    padding-top: 50px;
    h1 {
        margin-top: -15px;
        margin-bottom: 30px;
    }
}

.section-builders {
    h4 {
        font-weight: bold;
        margin-bottom: 30px;
    }
    p {
        font-weight: 200;
    }
    .box {
        position: relative;
        margin-bottom: 0;
        padding: 200px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.box-left {
            .box-image {
                left: 0;
                right: auto;
            }
        }
        .box-image {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 50%;
            .box-image-bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: $primary;
                background-size: cover;
                background-position: 50%;
            }
        }
    }
}

.section-download {
    margin-top: 100px;
}
ul.file-list {
    margin-top: 40px;
    li.file-list__item {
        &:not(:last-of-type) {
            border-bottom: 1px solid $gray-300;
        }
        a.link-download {
            color: $text-color;
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            .link-download__btn {
                display: flex;
                align-items: center;
                .icon {
                    margin-left: 5px;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {
    .page-header {
        .section-title {
            padding-top: 30px;
        }
    }
    .section-builders {
        h4 {
            font-weight: bold;
            margin-bottom: 15px;
        }
        .box {
            display: flex;
            flex-direction: column;
            padding: 0;

            .box-image {
                position: relative;
                width: 100%;
                padding-bottom: 62%;
                .box-image-bg {
                }
            }
            &.box-right {
                flex-direction: column-reverse;
            }
            .box-content {
                padding-top: 20px;
                padding-bottom: 5px;
            }
        }
    }
    .section-download {
        margin-top: 30px;
        ul.file-list {
            margin-top: 20px;
            li.file-list__item {
                a.link-download {
                    display: block;
                }
            }
        }
    }

}


@include media-breakpoint-down(sm) {

}



