// 
// layout: footer
//

.header{
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    background-color: $white;
    transition: all 0.3s;
    
    .header-row{
        position: relative;
        padding: 20px 0;

        &:after {
            content: '';
            height: 105px;
            width: 342px;
            background: url(../img/fala.png);
            background-position: center bottom;
            background-repeat: no-repeat;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
    }

    .header-center {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .header-right {
        position: absolute;
        right: 50px;
        top: 20px;
        display: flex;
    }

    .header-brand{
        margin-left: 80px;
        margin-right: 80px;
        position: relative;
        margin-bottom: -96px;
        img{
            display: block;
            position: relative;
            z-index: 2;
        }
    }
    .header-lang {
        padding-top: 20px;
    }

    ul.header-social {
        display: flex;
        padding-right: 30px;
        border-right: 1px solid $gray-300;
        li{
            margin-left: 15px;
        }
        li a {
            color: $text-color;
            padding: 22px 0;
            display: block;
        }
    }
    ul.header-menu{
        display: flex;
        &:first-of-type {
            justify-content: flex-end;
        }
        margin: 0;
        padding: 0;
        min-width: 518px;
        li{
            margin-right: 13px;
            margin-left: 13px;
        }
        li a{
            color: $text-color;
            font-size: 14px;
            font-weight: bold;
            display: block;
            padding: 20px 0px;
            position: relative;
            text-transform: uppercase;
            &:hover{
                color: $primary;
            }
        }
        li a.active{
            color: $primary;
        }
    }



    .dropdown-link {
        background-color: $primary;
        color: $text-color !important;
        border-color: $primary;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        min-width: 55px;
        position: relative;
        outline: none!important;
        display: inline-block;
        .arrow-round {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            background-color: $blue;
            box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.45);
            [class^="icon"] {
                color: $white;
                transition: 0s;
            }
        }
    }

    .dropdown-link-container {
        .dropdown-menu {
            min-width: 100%;
            background-color: $primary;
            padding: 0;
            border-radius: 5px;
        }
        .dropdown-item {
            padding: 8px 4px;
            text-align: center;
            color: $white;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
            background-color: darken($primary, 2.0);
            color: $white;
        }
    }

    .dropdown-link-container.show {
        .arrow-round [class^="icon"] {
            transform: rotate(180deg);
        }
    }


    .dropdown-style2{

        cursor: pointer;
        .dropdown-link{
            background-color: transparent;
            box-shadow: none;
            padding: 0px 15px;
            min-width: 45px;
            margin-left: 10px;
        }

        .icon-arrow-down{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size:6px;
        }

        .dropdown-item{
            border-radius: 0px;
        }

        .dropdown-menu{
            border-radius: 0px;
            border:0px;
            margin-top: 0;
        }
    }

    .header-toggler{
        display: none;
    }
}

.redirect {
    position: fixed;
    right: -112px;
    top: 50%;
    z-index:999;
    transition: all .4s cubic-bezier(.365,.84,.44,1);
    &:hover{
        right:0;
    }
    transform: translateY(-50%);
    .redirect-title {
        background-color: $primary;
        padding: 20px;
        font-weight: bold;
        color: $white;
        transform: translate(-50%,-50%) rotate(90deg);
        position: absolute;
        top: 50%;
        left: -30px;
        width:235px;
        text-align:center;
    }
    .redirect-companies {
        background-color: $white;
        margin-bottom: 0;
        padding: 0 10px;
        li {
            padding: 35px 10px;
            &:first-of-type {
                border-bottom: 1px solid $gray-300;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .header {
        .header-brand {
            margin-left: 20px;
            margin-right: 20px;
        }
        ul.header-menu {
            min-width: 389px;
            li {
                margin-right: 5px;
                margin-left: 5px;
            }
        }
        .header-right {

            right: 0;
            ul.header-social {
                padding-right: 15px;
            }
        }
    }
}
@include media-breakpoint-down(lg) {

    .header{
        .header-brand{
            img{
                max-width: 100px;
            }
        }
        ul.header-menu li {
            margin-right: 7px;
            margin-left: 7px;
        }
        .header-right {
            right: 0;
            ul.header-social {
                display: none;
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .header {
        .header-row {
            padding: 25px 0;
        }
        .header-brand {
            margin-left: 0;
            margin-right: 0;
        }
        ul.header-menu, .header-phone, ul.header-lang, .header-phone .phone-gradient {
            display: none;
        }
        .header-toggler{
            display: block;
        }
        .header-right {
            right: 15px;
            .header-lang {
                padding-top: 0;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .header {
        .header-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            background-color: $white;
            &:after {
                content: none;
            }
        }
        .header-center {
            justify-content: flex-start;
        }
        .header-brand {
            margin-bottom: 0;
            img {
                max-height: 50px;
                height: auto;
            }
        }
        .header-right {
            position: relative;
            right: 0;
            top: 0;
        }

    }
    .redirect {
        display: none;
    }
}

