// 
// page: about
// 

.section-chef {
  padding: 50px 0;
  h2, .h2 {
    margin-top: -10px;
    margin-bottom: 40px;
  }
  .chef-content {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 20px;
  }
  h6 {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;

  }
  h5 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 25px;
  }
  p {
    font-weight: 200;
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(sm) {

}


